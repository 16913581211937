import { apiEmailFromTargeter } from '@ateams/api';
import { useStores } from '@src/stores';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from './keys';
import { reachoutAutomationApi } from '@src/logic/services/endpoints';

export const useMutationCloseEmailFromTargeterUsageLog = () => {
  const { auth } = useStores();
  return useMutation({
    mutationFn: async ({
      body,
      gptUsageLogId,
    }: {
      body: string;
      gptUsageLogId: string;
    }) => {
      if (!body || !gptUsageLogId) {
        throw new Error('Invalid parameters');
      }
      return apiEmailFromTargeter.closeEmailFromTargeterGptUsageLog(
        auth,
        gptUsageLogId,
        body,
      );
    },
  });
};

export const useGetReachoutAutomationPreviewForMission = ({
  missionId,
  maxNumberOfBuilders,
  enabled = true,
}: {
  missionId: string;
  maxNumberOfBuilders: number;
  enabled?: boolean;
}) => {
  const { auth } = useStores();
  return useQuery({
    queryKey:
      queryKeys.emailFromTargeter.getReachoutAutomationPreviewForMission(
        missionId,
      ),
    queryFn: () => {
      return reachoutAutomationApi.reachoutAutomationControllerGetMissionPreview(
        {
          mid: missionId,
          audiencePresetLimit: maxNumberOfBuilders,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          },
        },
      );
    },
    enabled,
  });
};
